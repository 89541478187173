<template>
  <div class="no-gutter justify-center">
    <div class="text-center mt-12">
      <img :src="iconOk"/>
      <p>
        <span style="font-size: 20px;">
          <strong> Realizaste exitosamente la reserva. </strong>
        </span>
        <br/>
        Al correo electrónico te enviamos el documento resumen de esta
        transacción, que hace las veces de contrato por la reserva y compra del
        stand.
        <br/>
        Si requieres hacer alguna modificación, por favor comunícate con nosotros.
      </p>
      <a
        href="http://fise.co"
        class="mt-4 elevation-0 text-none mr-2 v-btn v-btn--contained theme--light v-size--default primary"
      >Regresar a la página de inicio</a
      >
    </div>
  </div>
</template>

<script>
import iconOk from '@/assets/images/icon-ok.jpg'

export default {
  name: 'BookingSuccessful',
  data: function () {
    return {
      iconOk: iconOk
    }
  }
}
</script>
